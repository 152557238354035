import Cookies from 'js-cookie';

export default function tableSort() {
    if ($('[data-sort-col]').length) {
        const sortElements = $('[data-sort-col]:visible');
        const tableId = sortElements.closest('table').attr('id');
        const sortTrigger = $('[data-sort-col] .table__sort');
        const cookieName = `${tableId}-sort-options`;
        let tableSortSetup = {};

        if (Cookies.get(cookieName) === undefined) {
            Cookies.set(cookieName, startingSetup(sortElements));
        }

        tableSortSetup = {...JSON.parse(Cookies.get(cookieName))};
        setClasses('', tableSortSetup);
        const sortingItem =  Object.keys(tableSortSetup).filter(key => tableSortSetup[key].sort === true);
        if (sortingItem.length) {
            const parent = $(`[data-sort-col='${sortingItem}']`);
            // it requires reversed direction to load properly
            sortElements.closest('table:visible').each(function() {
                sortRows(this, sortingItem, !tableSortSetup[sortingItem].direction, parent.data('sort-mode'));
            })
        }

        sortTrigger.off('click').on('click', function(e) {
            e.stopPropagation();
            const parent = $(this).closest('[data-sort-col]');
            const sortParameter = parent.data('sort-col');
            const localTable = $(this).closest('table:visible')[0];
            const sortDirection = tableSortSetup[sortParameter].direction;
            const sortMode = parent.data('sort-mode');

            setSortData(parent, localTable, tableSortSetup, cookieName);
            sortRows(localTable, sortParameter, sortDirection, sortMode);
        })
    }
}

function startingSetup(sortElements) {
    const newSetup = {};
    sortElements.each(function() {
        const key = $(this).data('sort-col');
        newSetup[key] = {
            sort: false,
            // false - ascending, true - descending
            direction: true
        };
    });

    const firstEl = newSetup[sortElements.first().data('sort-col')];
    firstEl.sort = true;
    firstEl.direction = false;

    return newSetup;
}

function setSortData(parent, table, tableSortSetup, cookieName) {
    const sortParameter = parent.data('sort-col');
    const sortMode = parent.data('sort-mode');
    const sortDirection = tableSortSetup[sortParameter].direction;

    Object.keys(tableSortSetup).forEach(function(key) {
        tableSortSetup[key].sort = false;
    });
    tableSortSetup[sortParameter].sort = true;
    tableSortSetup[sortParameter].direction = !sortDirection;
    // Save cookie with new setup for this table
    setClasses(table, tableSortSetup);
    Cookies.set(cookieName, tableSortSetup);
}

// params: table we are sorting, column, in which direction, special more for parsing
function sortRows(table, field, direction, mode = '') {
    const rows = table.rows;
    let sorting = true;
    let switching = false;
    let a, b, i;
    while (sorting) {
        sorting = false;
        for (i = 1; i < (rows.length - 1); i++) {
            switching = false;
            a = rows[i].querySelector(`[data-sort-field='${field}']`).innerText;
            b = rows[i + 1].querySelector(`[data-sort-field='${field}']`).innerText;
            if (mode === 'number') {
                a = parseInt(a);
                b = parseInt(b);
            }
            if (direction) {
                if (a > b) {
                    switching = true;
                    break;
                }
            } else {
                if (a < b) {
                    switching = true;
                    break;
                }
            }
        }
        if (switching) {
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            sorting = true;
        }
    }
}

function setClasses(table, tableSortSetup) {
    Object.keys(tableSortSetup).forEach(function(key) {
        const field = $(table).find(`[data-sort-col='${key}']`);
        if (tableSortSetup[key].sort) {
            if (tableSortSetup[key].direction) {
                field.removeClass('ascending').addClass('descending');
            } else {
                field.removeClass('descending').addClass('ascending');
            }
        } else {
            field.removeClass('ascending descending');
        }
    });
}
