export default function columnEditor() {
    const visibleCols = [];
    const hiddenCols = [];
    const table = $('[data-table-hideable]:visible');

    getCols(table, visibleCols, hiddenCols);
}

function getCols(table, visCols, hidCols) {
    table.find('[data-col-hideable]:visible').each(function() {
        visCols.push(this);
    });
    table.find('[data-col-hideable]:hidden').each(function() {
        hidCols.push(this);
    })
}

function saveLayout() {

}