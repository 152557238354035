import jQuery from 'jquery';
import Foundation from 'foundation-sites';
import tableSort from './tableSort';
import columnEditor from './columnEditor';
import PerfectScrollbar from 'perfect-scrollbar';
import Dropzone from './vendor/dropzone.js';
import slick from 'slick-carousel';
import * as charts from 'chart.js';
import loader from './components/loader';
import { read } from 'fs';

const app = {
  foundation: () => {
    Foundation.Dropdown.defaults.hOffset = 0;
    Foundation.Dropdown.defaults.vOffset = 0;
    Foundation.Dropdown.defaults.closeOnClick = true;
    Foundation.Equalizer.defaults.equalizeOnStack = true;

    jQuery(document).foundation();
  },
  scrollArray: [],
  start: () => {
    app.foundation();
    app.sidebar();
    app.select();
    app.charts();
    app.table();
    app.selectRows();
    app.setScrolls();
    app.form();
    app.dragDrop();
    app.sortingTables();
    app.notifications();
    app.toggleListingButton();
    app.gallery();
    app.modals();
    app.datepickers();
    app.imageUpload();
    app.relationBrands();
    app.filters();
    app.toggleClaimInput();
    app.scrollToTop();
    app.triggers();
    app.dropzones();
    app.damagePanels();
    app.toast();
    app.replaceLabel();
    app.headSearch();
    app.countdown();
    columnEditor();
  },
  sidebar: () => {
    const sideBtn = $('.sidebar__hamburger');
    const sideClose = $('.sidebar__close');
    const pageWrapper = $('.site-main');

    sideBtn.on('click', function(e) {
      e.preventDefault();

      if (!pageWrapper.hasClass('sidebar-is-open')) {
        pageWrapper.addClass('sidebar-is-open');
      } else {
        pageWrapper.removeClass('sidebar-is-open');
      }
    });
    sideClose.on('click', function(e) {
      e.preventDefault();
      pageWrapper.removeClass('sidebar-is-open');
    })
  },
  select: () => {
    $(document).on('scroll, mousewheel', function(e) {
      $('.modal:visible .select-drop').select2('close');
    });
    $(document).on('scroll, mousewheel', '.select2-dropdown', function(e) {
      e.stopPropagation();
    });
      $(document).on("select2:select", '.select-drop-multi', function (evt) {
          var element = evt.params.data.element;
          var $element = $(element);

          $element.detach();
          $(this).append($element);
          $(this).trigger("change");
        });
      $(document).on('select2:unselect', '.select-drop-multi', function (e) {
          setTimeout(function() {
            const searchField = $(e.target).closest('.select-drop__box').find('.select2-search__field');
            searchField.val(null);
            $(e.target).select2('close');
          },1);
        });
      if ($('.select-drop--add-new').length) {
        const searchBox = $('.select-drop--add-new').closest('.select-drop__box');
        searchBox.on('keyup', '.select2-search__field', function(e) {
          const parentBox = $(this).closest('.select-drop__box');
          const select = parentBox.find('.select-drop--add-new');

          if(e.keyCode === 13) {
            const searchValue = $(this).val();
            if (select.find("option[value='" + searchValue + "']").length) {
              select.val(searchValue).trigger('change');
            } else {
                // Create a DOM Option and pre-select by default
                var newOption = new Option(searchValue, searchValue, true, true);
                // Append it to the select
                select.append(newOption).trigger('change');
            }
          }
        })
      }
      $(document).on('select2:select, select2:close', '.select-drop', function (evt) {
        setTimeout(function() {
          $(evt.target).select2('close');
        },1);
      });
      $(document).on('select2:open', '.select-drop', function(e) {
        if ($('.modal__content:visible:not(.modal__content--visible)').length) {
          const modalContent = $('.modal__content:visible');
          const modalContentBottom = modalContent.outerHeight() + modalContent.scrollTop();
          const dropDown = $(e.target).closest('.select-drop__box');
          const dropDownBottom = dropDown.offset().top + dropDown.find('.select2-results').outerHeight() + modalContent.scrollTop();

          dropDown.removeClass('select-drop__box--up');

          if (dropDownBottom > modalContent[0].scrollHeight) {
            dropDown.addClass('select-drop__box--up');
          }

          if (modalContentBottom < dropDownBottom) {
            dropDown.addClass('select-drop__box--up');
          }
        }
      });
      $(document).on('change', '.select-drop', function(e) {
        const parent = $(e.target).closest('.select-drop__box');
        if (e.target.value && $(e.target).find("option[value='']").length > 0) {
          parent.find('.form__input-clear').fadeIn(200);
        } else {
          parent.find('.form__input-clear').fadeOut(200);
        }
      });
      $('.select-drop').each(function() {
        const searchFlag = $(this).hasClass('select-drop--search') ? 4 : Infinity;
        $(this).select2({
          minimumResultsForSearch: searchFlag,
          dropdownParent: $(this).closest('.select-drop__box'),
          closeOnSelect: true
        });
        $(this).trigger('change');
      });
      $(document).on('open.zf.reveal', function() {
        var modal = $(this).find('.modal:visible');
        modal.find('.select-drop').each(function() {
          const searchFlag = $(this).hasClass('select-drop--search') ? 4 : Infinity;
          $(this).select2({
            minimumResultsForSearch: searchFlag,
            dropdownParent: $(this).closest('.select-drop__box'),
            closeOnSelect: true
          });
          $(this).trigger('change');
          $(this).on('select2:select, select2:close', function (evt) {
            setTimeout(function() {
              $(evt.target).select2('close');
            },1);
          });
        });
      });
  },
  table: () => {
    if ($('.table__section:not(.table__section--wide) .resizeable-table').length) {
      function resizableGrid(table) {
        if (table) {
          var row = table.getElementsByTagName('tr')[0],
          cols = row ? row.children : undefined;
          if (!cols) return;

          table.style.overflow = 'hidden';

          var tableHeight = table.offsetHeight;

          for (var i=0;i<cols.length;i++){
            var div = createDiv(tableHeight);
            cols[i].appendChild(div);
            cols[i].style.position = 'relative';
            setListeners(div);
          }

          function setListeners(div){
            var pageX,curCol,nxtCol,curColWidth,nxtColWidth;

            div.addEventListener('mousedown', function (e) {
              curCol = e.target.parentElement;
              nxtCol = curCol.nextElementSibling;
              pageX = e.pageX;

              var padding = paddingDiff(curCol);

              curColWidth = curCol.offsetWidth - padding;
              if (nxtCol)
              nxtColWidth = nxtCol.offsetWidth - padding;
            });

            document.addEventListener('mousemove', function (e) {
              if (curCol) {
                var diffX = e.pageX - pageX;

                if (nxtCol) {
                  if (nxtColWidth - (diffX) > 86 && (curColWidth + diffX) > 86) {

                    nxtCol.style.width = (nxtColWidth - (diffX))+'px';
                    curCol.style.width = (curColWidth + diffX)+'px';
                  }
                }

              }
            });

            document.addEventListener('mouseup', function (e) {
              curCol = undefined;
              nxtCol = undefined;
              pageX = undefined;
              nxtColWidth = undefined;
              curColWidth = undefined
            });
          }

          function createDiv(height){
            var div = document.createElement('div');
            div.className = 'table__resizer';
            div.style.height = height + 'px';
            return div;
          }

          function paddingDiff(col){

            if (getStyleVal(col,'box-sizing') == 'border-box'){
              return 0;
            }

            var padLeft = getStyleVal(col,'padding-left');
            var padRight = getStyleVal(col,'padding-right');
            return (parseInt(padLeft) + parseInt(padRight));

          }

          function getStyleVal(elm,css){
            return (window.getComputedStyle(elm, null).getPropertyValue(css))
          }
        }
      };

      const table = document.getElementsByClassName('resizeable-table')[0];
      resizableGrid(table);

      $(document).on('change.zf.tabs', () => {
        setTimeout(function() {
          const table = document.querySelector('.tabs-panel.is-active .resizeable-table');
          resizableGrid(table);
        },1000)
      })
    }
  },
  selectRows: () => {
    if ($('[data-select]')) {
      const check = $('[data-select]');
      check.on('change', function() {
        const action = $(this).data('select');
        switch (action) {
          case 'row': {
            const parentRow = $(this).closest('.table__row');
            if ($(this).prop('checked') === true) {
              parentRow.addClass('table__row--selected');
            } else {
              parentRow.removeClass('table__row--selected');
            }
          }
            break;
          // case 'all': {
          //   const parent = $(this).closest('table');
          //   const rows = parent.find('[data-selectable=all]').find('.table__row');
          //   if ($(this).prop('checked') === true) {
          //     rows.each(function() {
          //       $(this).addClass('table__row--selected');
          //       $(this).find('[data-select=row]').prop('checked', true);
          //     })
          //   } else {
          //     rows.each(function() {
          //       $(this).removeClass('table__row--selected');
          //       $(this).find('[data-select=row]').prop('checked', false);
          //     })
          //   }
          // }
          //   break;
          default:
            break;
        }
      });
    }
  },
  sortingTables: () => {
    tableSort();
    $('.tabs-sorting').on('change.zf.tabs', () => tableSort());
  },
  dragDrop: () => {
    $('[data-col-dnd=col-edit-left]').sortable({
      connectWith: '[data-col-dnd=col-edit-right]',
      helper: 'clone',
      appendTo: '.column-editor',
      stop: () => {
        app.scrollsUpdate();
      }
    }).disableSelection();
    $('[data-col-dnd=col-edit-right]').sortable({
      connectWith: '[data-col-dnd=col-edit-left]',
      helper: 'clone',
      appendTo: '.column-editor',
      stop: () => {
        app.scrollsUpdate();
      }
    }).disableSelection();
    $('[data-sortable-photo]').sortable({
      // helper: 'clone',
      appendTo: '.modal__content'
    }).disableSelection();
  },
  setScrolls: () => {
    app.scrollArray.length = 0;
    $('.perf-scroll').each(function(key) {
      app.scrollArray.push(`ps${key}`);
      app[`ps${key}`] = new PerfectScrollbar(this);
    })
  },
  scrollsUpdate() {
    if ('.perf-scroll:visible') {
      app.scrollArray.map(scroll => app[scroll].update());
    }
  },
  notifications() {
    const notMenu = $('.notification__menu');
    const notBtn = $('.header__notifications');
    const notClose = $('.notification__close');

    notBtn.on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();

      let notHeight = '';
      const windowPos = $(window).scrollTop();
      const headerHeight = $('.header').outerHeight();

      if (headerHeight - windowPos < 0) {
        notHeight = '100%';
      } else {
        notHeight = `calc(100% - ${headerHeight - windowPos}px)`;
      }

      notMenu.css('height', notHeight);

      notMenu.toggleClass('notification__menu--open');
      $('.notification__backdrop').toggleClass('notification__backdrop--open');
      $('body').toggleClass('body--locked');
    });

    notClose.on('click', function(e) {
      e.stopPropagation();
      notMenu.removeClass('notification__menu--open');
      $('.notification__backdrop').removeClass('notification__backdrop--open');
      $('body').removeClass('body--locked');
    });

    notMenu.on('click', function(e) {
      e.stopPropagation();
    });

    $(document).on('click', function() {
      if ($('.notification__menu--open').length) {
        notMenu.removeClass('notification__menu--open');
        $('.notification__backdrop').removeClass('notification__backdrop--open');
        $('body').removeClass('body--locked');
      }
    });
  },
  toggleListingButton() {
    $(document).on('change.zf.tabs', () => {
      if ($('[data-toggle-btn]').length) {
        setTimeout(function() {
          const btnId = $('.tabs-title.is-active a').data('toggle-btn');
          switch (btnId) {
            case '#new-relation': {
              $('#new-contact').hide();
              $('#new-relation').show();
            }
              break;
            case '#new-contact': {
              $('#new-relation').hide();
              $('#new-contact').show();
            }
              break;
            default:
              break;
          }
        },1);
      }
    })
  },
  gallery() {
    if ($('.gallery__main-slider').length) $('.gallery__main-slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      fade: true,
      asNavFor: '.gallery__thumbs-slider',
      adaptiveHeight: true,
      appendArrows: $('.gallery__main-arrows')
    });
    if ($('.gallery__thumbs-slider').length) $('.gallery__thumbs-slider').slick({
      slidesToShow: 7,
      arrows: false,
      asNavFor: '.gallery__main-slider',
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1820,
          settings: {
            slidesToShow: 6,
          }
        },
        {
          breakpoint: 1420,
          settings: {
            slidesToShow: 5,
          }
        },
      ],
    });
    if ($('.gallery__slide img').length) {
      $('.gallery__slide img').featherlightGallery({
        previousIcon: '',
		    nextIcon: '',
      });
    }
  },
  autocomplete() {

  },
  modals() {
    $(document).on('open.zf.reveal', function() {
      var modal = $(this).find('.modal:visible');
      if (modal.find('[data-col-dnd]').length) {
        app.dragDrop();
      }
    });
  },
  datepickers: () => {
      if ($('.form__input--date').length) {
          $('.form__input--date').each(function(){
              var options = {
                  changeMonth: true,
                  changeYear: true,
                  dayNamesMin: ["S", "M", "T", "W", "T", "F", "S"]
                  // monthNamesShort: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
              };
              if($(this).data('date-format')){
                  options.dateFormat = $(this).data('date-format');
              }
              if($(this).data('alt-field')){
                  options.altFormat = "yy-mm-dd";
                  options.altField = '#' + $(this).data('alt-field');
              }
              $(this).datepicker(options);
          });
      }
  },
  imageUpload: () => {
    function readImageURL(input, target) {
      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = function(e) {
          $(target).attr('src', e.target.result)
        }

        reader.readAsDataURL(input.files[0]);
      }
    }

    $('#logo-upload').on('change', function() {
      readImageURL(this, '#logo-upload-target');
    })
  },
  relationBrands: () => {
    const brandSearchContainer = $('#search-for-brand-container');
    const brandSearch = $('#search-for-brand');
    const acceptBtn = $('#add-brand');
    const brandSelectTrigger = $('#add-brand-trigger');

    brandSelectTrigger.on('click', function() {
      $(this).hide();
      brandSearchContainer.show(0, function() {
        $(this).css('display', 'flex');
      });
    })

    brandSearch.on('change', function() {
      console.log($(this).val())
      if ($(this).val() !== '') {
        acceptBtn.addClass('acceptable');
      } else {
        acceptBtn.removeClass('acceptable');
      }
    });

    acceptBtn.on('click', function() {
      brandSearchContainer.hide();
      brandSelectTrigger.show();
    });
  },
  filters: () => {
    if ($('.filters')) {
      $('.filters').on('click', function(e) {
        e.stopPropagation();
      });
      $(document).on('click', function() {
        if ($('.filters--open').length) {
          $('.filters').removeClass('filters--open');
          $('.filters__backdrop').removeClass('filters__backdrop--open');
          $('body').removeClass('body--locked');
        }
      });
      const filters = $('.filters');
      const filtersBtnBox = $('.filters .filters__btn-box');
      $('.filters__btn').on('click', (e) => {
        e.stopPropagation();
        if (filters.hasClass('filters--open')) {
          filters.removeClass('filters--open');
          $('.filters__backdrop').removeClass('filters__backdrop--open');
          $('body').removeClass('body--locked');
        } else {
          filters.addClass('filters--open');
          $('.filters__backdrop').addClass('filters__backdrop--open');
          $('body').addClass('body--locked');
        }
      })
      $('#filters-clear-all').on('click', (e) => {
        e.stopPropagation();
        $('#filters-form')[0].reset();
        $('#filters-form').find('.select-drop').each(function(){
          $(this).trigger('change');
        });
      });
      if ($('[data-field-toggle]')) {
        $('[data-field-toggle]').each(function() {
          const fieldsArea = $(this);
          $(this).on('change', 'input', function(e) {
            if (fieldsArea.data('toggle-value') == $(this).val()) {
              $(fieldsArea.data('field-toggle')).removeClass('hidden');
            } else {
              $(fieldsArea.data('field-toggle')).addClass('hidden');
            }
          })
        })
      }
    }
  },
    form: () => {
        $('[data-clear=single]').on('click', function (e) {
            e.preventDefault();
            const formBox = $(this).closest('.form__box');
            if (formBox.find('.form__input').length) {
                formBox.find('.form__input').val(null);
            }
            if (formBox.find('.select-drop')) {
                formBox.find('.select-drop').val(null).trigger('change');
            }
        });

        $(document).on('keyup', 'input', function () {
            if ($(this).val() !== '') {
                $(this).addClass('form__input--filled');
            } else {
                $(this).removeClass('form__input--filled');
            }
        });
        $(document).on('click', '.form__input-clear', function (e) {
            const formBox = $(this).closest('.form__input--clearable');
            e.preventDefault();
            if (formBox.find('.form__input').length) {
                const input = formBox.find('.form__input');
                input.val(null);
                input.removeClass('form__input--filled');
            }
            if (formBox.find('.select-drop')) {
                formBox.find('.select-drop').val(null).trigger('change');
            }
            if(formBox.find('input[type=search]').length){
                formBox.find('input[type=search]').trigger('keyup');
            }
        })
    },
  dropzones: () => {
      $('.dropzone-field').each(function() {
          if ($(this)[0].dropzone) {
              return;
          }
          var eventPrefix = $(this).data('event-prefix');
          var options = {
              init: function(){
                  this.on('error', function(file, response){
                      $('body').trigger('dropzoneUploadError', response);
                      callDropZoneSpecificEvent(eventPrefix, 'dropzoneUploadError', response);
                  });
                  this.on('success', function(file, response){
                      $('body').trigger('dropzoneUploadSuccess', response);
                      callDropZoneSpecificEvent(eventPrefix, 'dropzoneUploadSuccess', response);
                  });
                  this.on("complete", function(file) {
                      if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                      } else {
                          this.processQueue();
                      }
                  });
                  this.on("queuecomplete", function (progress) {
                      $('body').trigger('dropzoneQueueComplete');
                      callDropZoneSpecificEvent(eventPrefix, 'dropzoneQueueComplete', progress);
                      this.removeAllFiles(true);
                  });
              },
              parallelUploads: 3,
              uploadMultiple: true,
          };

          if ($(this).data('url')) {
              $.extend(options, {
                  url: $(this).data('url'),
              });
          }

          if ($(this).data('param-name')) {
              $.extend(options, {
                  paramName: $(this).data('param-name'),
              });
          }

          if ($(this).data('single-upload')) {
              $.extend(options, {
                  uploadMultiple: false,
              });
          }
          $(this).dropzone(options);
      });

      function callDropZoneSpecificEvent(eventPrefix, eventName, data)
      {
          if (eventPrefix !== 'undefined') {
              $('body').trigger(eventPrefix + '-' + eventName, data);
          }
      }
  },
  damagePanels: () => {
    $('.modal__content').on('change', '[data-damage]', function() {
      const damageType = $(this).data('damage');
      $('[data-damage-panel]').hide();
      $(`[data-damage-panel=${damageType}]`).show();
    })
  },
  toast: () => {
    toastr.options = {
      "closeButton": true,
      "debug": false,
      "newestOnTop": false,
      "progressBar": false,
      "positionClass": "toast-top-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "10000",
      "hideDuration": "10000",
      "timeOut": "10000",
      "extendedTimeOut": "5000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
  },
  replaceLabel: () => {
    $('[data-replace-label]').on('change', function (e) {
      const names = [];
      for(let i=0; i < this.files.length; ++i) {
        names.push(this.files[i].name)
      }
      $(this).closest('.form__box').find('.form__input-file-label')[0].innerHTML = names.join(', ');
    });
  },
  headSearch: () => {
    const trigger = $('.search__trigger');
    const search = $('.search__input-box--head');

    trigger.on('click', function() {
      $(this).addClass('hidden');
      search.addClass('open');
    });

    search.find('.form__input--search').on('blur', function() {
      search.removeClass('open');
      trigger.removeClass('hidden');
    });
  },
  countdown: () => {
    const countdowns = $('.counter__time');
    countdowns.each(function() {
      const timerEl = $(this)[0];
      let timerStart = timerEl.innerHTML;
      const interval = setInterval(function() {

        var timer = timerStart.split(':');
        //by parsing integer, I avoid all extra string processing
        let hours = parseInt(timer[0], 10);
        let minutes = parseInt(timer[1], 10);
        let seconds = parseInt(timer[2], 10);
        --seconds;
        minutes = (seconds < 0) ? --minutes : minutes;

        hours = (minutes < 0) ? --hours : hours;
        if (hours < 0) clearInterval(interval);

        minutes = (minutes < 0) ? 59 : minutes;
        minutes = (minutes < 10) ? '0' + minutes : minutes;

        seconds = (seconds < 0) ? 59 : seconds;
        seconds = (seconds < 10) ? '0' + seconds : seconds;

        let newTime = hours + ':' + minutes + ':' + seconds;
        if (hours < 0) {
          timerEl.innerText = 'Expired'
        } else {
          timerEl.innerText = newTime;
        };
        timerStart = newTime;
      }, 1000);
    })

  },
  charts: () => {
    Chart.defaults.global.defaultFontFamily = 'Poppins, sans-serif';
    Chart.defaults.global.defaultFontColor = '#7a7b81';
    Chart.defaults.global.legend.position = 'right';
    Chart.defaults.global.legend.labels.boxWidth = 8;
    Chart.defaults.global.legend.labels.usePointStyle = true;
    Chart.defaults.global.legend.labels.padding = 18;
    Chart.defaults.global.legend.labels.fontColor = '#52545f';

    if($('#applications-chart').length) {
      const labels = [];
      const dataSets = [];
      const dataTable = $('#application-table');
      const rowNumber = dataTable.find('.table__body [data-chart-row]').length;
      dataTable.find('.table__head th').each(function() {
        if ($(this)[0].innerText != '') {
          labels.push($(this)[0].innerText);
        }
      });
      dataTable.find('.table__body [data-chart-row]').each(function() {
        const row = $(this);
        const dataset = {};
        const data = [];
        const numbers = row.find('.table__cell:not([data-chart-row-title]) .table__cell-text');
        dataset.label = row.find('[data-chart-row-title] .table__cell-text')[0].innerText;
        numbers.each(function() {
          data.push(parseInt($(this)[0].innerText));
        })
        dataset.data = data;
        dataset.backgroundColor = $(this).data('chart-color');
        dataSets.push(dataset);
      });

      var ctx = document.getElementById('applications-chart').getContext('2d');
      var myChart = new Chart(ctx, {
        type: 'bar',
        maintainAspectRatio: true,
        data: {
            labels: labels,
            datasets: dataSets
        },
        options: {
          scales: {
            xAxes: [{
              maxBarThickness: 2,
              categoryPercentage: 0.06 * rowNumber,
              barPercentage: 0.8,
              gridLines: {
                display: false,
                zeroLineColor: 'transparent'
              }
            }],
            yAxes: [{
              ticks: {
                padding: 10,
                beginAtZero: true
              },
              gridLines: {
                borderDash: [8,14],
                lineWidth: 2,
                zeroLineColor: 'transparent',
                color: 'rgba(0,0,0,.05)'
              }
            }]
          }
        }
      });
    }
    if($('#assigned-cars-chart').length) {
      const labels = [];
      const dataSets = [];
      const dataTable = $('#assigned-cars-table');
      const rowNumber = dataTable.find('.table__body [data-chart-row]').length;
      dataTable.find('.table__head th').each(function() {
        if ($(this)[0].innerText != '') {
          labels.push($(this)[0].innerText);
        }
      });
      dataTable.find('.table__body [data-chart-row]').each(function() {
        const row = $(this);
        const dataset = {};
        const data = [];
        const numbers = row.find('.table__cell:not([data-chart-row-title]) .table__cell-text');
        dataset.label = row.find('[data-chart-row-title] .table__cell-text')[0].innerText;
        numbers.each(function() {
          data.push(parseInt($(this)[0].innerText));
        })
        dataset.data = data;
        dataset.backgroundColor = $(this).data('chart-color');
        dataSets.push(dataset);
      });

      var ctx = document.getElementById('assigned-cars-chart').getContext('2d');
      var myChart = new Chart(ctx, {
        type: 'bar',
        maintainAspectRatio: true,
        data: {
            labels: labels,
            datasets: dataSets
        },
        options: {
          scales: {
            xAxes: [{
              maxBarThickness: 2,
              categoryPercentage: 0.06 * rowNumber,
              barPercentage: 0.8,
              gridLines: {
                display: false,
                zeroLineColor: 'transparent'
              }
            }],
            yAxes: [{
              ticks: {
                padding: 10,
                beginAtZero: true
              },
              gridLines: {
                borderDash: [8,14],
                lineWidth: 2,
                zeroLineColor: 'transparent',
                color: 'rgba(0,0,0,.05)'
              }
            }]
          }
        }
      });
    }
  },
  toggleClaimInput: () => {
    $('[data-claim-trigger-edit]').on('click', function() {
      const $parent = $(this).closest('[data-claim-form-box]');
      const $value = $parent.find('[data-claim-form-value]');
      const $edit = $parent.find('[data-claim-form-input]');
      $value.hide();
      $edit.show();
    });
    $('[data-claim-confirm-edit]').on('click', function() {
      const $parent = $(this).closest('[data-claim-form-box]');
      const $value = $parent.find('[data-claim-form-value]');
      const $edit = $parent.find('[data-claim-form-input]');
      $edit.hide();
      $value.show();
      $('body').trigger('claimValueSaved');
    });
  },
  scrollToTop: () => {
    
    function smoothScroll() {
      $('html, body').animate({
        scrollTop: 0
      }, 600);
    };

    function calcPosition() {
      if (window.pageYOffset > 50) {
        $('.btn-to-top').addClass('visible');
      } else {
        $('.btn-to-top').removeClass('visible');
      }
    };

    $(window).on('scroll', calcPosition);
    $(document).on('click','.btn-to-top', smoothScroll);
    calcPosition();
  },
  triggers: () => {
    $('body').on('contentLoaded', function() {
      app.table();
      app.setScrolls();
      app.scrollsUpdate();
      app.damagePanels();
    });
    $('body').on('galleryUpdated', function() {
      app.gallery();
    });
    $('body').on('picturesLoaded', function() {
      app.dragDrop();
    });
    $('body').on('statisticsUpdated', function() {
      app.charts();
      app.table();
      app.sortingTables();
    });
    $('body').on('dropzoneUploadError', function() {
      $('.dropzone-field:visible').find('.dropzone-field__error-msg').show();
    });
  }
};

document.addEventListener('DOMContentLoaded', () => {
  app.start();
  loader();
});
