export default function loader() {
    var spinDiv = document.getElementById('spinner');

    $('body').on('openLoader', function() {
        spinDiv.style.display = 'block';
    });
    $('body').on('closeLoader', function() {
        spinDiv.style.display = 'none';
    });
}
